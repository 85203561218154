@import 'react-quill/dist/quill.snow.css';

.quill {
    .ql-toolbar {
        @apply rounded-t-md;
    }

    .ql-container {
        @apply font-sans text-base rounded-b-md;
    }

    button {
        @apply min-w-0 shadow-none rounded-none;
    }
}