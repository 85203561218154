.terms {
    h1 {
        @apply text-3xl font-semibold my-5;
    }
    h2 {
        @apply text-2xl font-semibold my-5;
    }
    h3 {
        @apply text-xl font-semibold my-5;
    }
    h4 {
        @apply text-lg font-medium my-5;
    }
    h5 {
        @apply text-lg font-medium my-5;
    }
    h6 {
        @apply text-base font-medium my-5;
    }
    
    li {
        @apply list-disc my-4 ml-8;
    }
    
    p {
        @apply my-4;
    }

    a {
        @apply underline text-blue-700;
    }
}