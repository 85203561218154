// Global select styling
.select {
    @apply text-base;

	.select__control {
		@apply 
			p-1 cursor-pointer
			border-gray-300 rounded-md;

		&.select__control--is-focused {
			@apply
				border-gray-400 ring ring-gray-200;
		}

        .select__multi-value {
            @apply rounded-full bg-white border border-gray-300;
        }

        .select__placeholder {
			@apply truncate text-gray-400;
		}
	}
	
	.select__menu {
		@apply mt-1;
	
		.select__menu-list {
			@apply divide-y;
	
			.select__option {
				@apply px-6 py-3 text-gray-900 text-left cursor-pointer;
	
				&.select__option--is-selected  {
					@apply bg-gray-100;
				}
				&.select__option--is-focused  {
					@apply bg-gray-100;
				}
			}
		}
	}
}

.agent-search-bar {
	.agent-search {
		.select__control {
			@apply
				w-full h-12 px-4 rounded-full
				text-gray-1000 bg-white border border-gray-300
				placeholder-gray-700
				cursor-text transition;

			&.select__control--is-focused {
				@apply
					bg-gray-100
					ring-4 ring-gray-200;
			}

			.select__input input {
				@apply focus:ring-0; // remove tailwind default focus styling
			}
		}

		&.large {
			.select__control {
				@apply 
					h-16 px-6 border-none bg-white;

				&.select__control--is-focused {
					@apply
						ring-4 ring-gray-200;
				}
			}
		}
	}
}

.filter {
	.select__control {
		@apply
			w-full h-10 pl-4 pr-2 py-0
			text-sm font-medium tracking-wider text-gray-1000
			bg-transparent border border-gray-200 rounded-full
			hover:bg-gray-100 hover:border-gray-200
			cursor-pointer transition
			focus:outline-none;

		&.select__control--is-focused {
			@apply
				bg-gray-100
				ring-0 ring-gray-400;
		}

		.select__placeholder {
			@apply text-gray-1000;
		}

		.select__input input {
			@apply focus:ring-0; // remove tailwind default focus styling
		}
	}
}