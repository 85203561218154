.ReactModal__Body--open {
    @apply overflow-hidden; // disable scrolling on background
}
.modal-overlay {
    @apply fixed inset-0 bg-black bg-opacity-25;
}

.modal {
    @apply 
        absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
        bg-white rounded-lg shadow
        focus:outline-none
        w-11/12 md:w-auto;
}