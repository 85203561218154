.accordion {
	@apply border-none divide-y-1;
	
	.accordion__button {
		@apply 
			flex justify-between items-center
			p-0 py-6
			text-xl font-medium
			bg-white outline-none
            cursor-pointer;

		&:after {
			content: '+';
			@apply font-light text-5xl text-gray-400 transition-all;
		}
		
		&[aria-expanded="true"]::after {
			@apply transform rotate-45;
		}
	}

	.accordion__panel {
		@apply 
			mb-8
			text-lg tracking-wide;
	}
}

.lists-accordion {
	@apply border-none divide-y-0 space-y-4;
	
	.accordion__item {
		@apply shadow-md rounded-xl;

		.accordion__button {
			@apply p-6 rounded-xl;
	
			&:after {
				@apply text-primary font-bold;
			}
		}

		.accordion__panel {
			@apply p-6 mb-0;
		}
	}
}