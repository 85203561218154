@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://use.typekit.net/wpo3kxi.css');
@import 'styles/Accordian.scss';
@import 'styles/Buttons.scss';
@import 'styles/DatePicker.scss';
@import 'styles/Forms.scss';
@import 'styles/Pagination.scss';
@import 'styles/ReactModal.scss';
@import 'styles/ReactQuill.scss';
@import 'styles/ReactSelect.scss';
@import 'styles/Tabs.scss';
@import 'styles/Terms.scss';

@layer base {
	html { 
		@apply text-gray-900; 
	}

	h1 {
		@apply text-8xl font-light;
	}
	h2 {
		@apply text-6xl font-light;
	}
	h3 {
		@apply text-5xl leading-tight;
	}
	h4 {
		@apply text-4xl leading-tight font-medium;
	}
	h5 {
		@apply text-2xl font-medium;
	}
	h6 {
		@apply text-xl font-medium;
	}

	.body-1 {
		@apply text-lg tracking-wide;
	}
	.body-2 {
		@apply text-sm;
	}
	.subtitle-1 {
		@apply text-base;
	}
	.subtitle-2 {
		@apply text-sm;
	}
	.button-text {
		@apply text-sm font-medium tracking-wider;
	}
	.caption {
		@apply text-xs tracking-wide;
	}
	.overline {
		@apply text-xs font-medium uppercase tracking-widest;
	}

	.card {
		@apply 
			rounded-xl shadow-md transition transform
			hover:shadow-lg hover:-translate-y-1
	}

	.pill {
		@apply 
			py-1 px-2
			rounded-full border border-gray-300
			text-gray-900;

		&.success {
			@apply text-green-600 bg-green-100 border-green-400;
		}
		&.error {
			@apply text-red-600 bg-red-100 border-red-400;
		}
	}

	.rich-text, .ql-snow .ql-editor {
		@apply text-lg;

		h1 {
			@apply text-6xl mb-4;
		}
		h2 {
			@apply text-4xl mb-3;
		}
		h3 {
			@apply text-2xl mb-2;
		}
		a {
			@apply text-primary underline;
		}
		ul {
			@apply list-disc list-inside pl-8;
		}
		ol {
			@apply list-decimal list-inside pl-8;
		}
	}
}