.react-tabs {
    .react-tabs__tab-list {
        @apply
            flex mb-6
            text-lg tracking-wide font-bold text-gray-400;
    
            .react-tabs__tab {
                @apply 
                    flex flex-grow justify-center p-4
                    border-0 border-b-4 border-gray-100
                    hover:text-gray-700 cursor-pointer;
                    
                    &.react-tabs__tab--selected {
                        @apply text-primary border-primary;
                    }
            }
    }
    .react-tabs__tab-panel {
        @apply text-lg;
    }
}