.form-input {
	label {
		@apply text-sm font-medium tracking-wider text-gray-800;
	}
	input, textarea, select, div.card-input {
		@apply 
			block text-base tracking-wide w-full
            px-3 py-2
			placeholder-gray-400
			border border-gray-300 rounded-md
			hover:border-gray-400 transition
			focus:border-gray-400 focus:ring focus:ring-gray-200;
        

		&.no-border {
			@apply
				border-opacity-0 hover:border-opacity-100;
		}
	}
    div.card-input {
        height: 42px;
        
        @apply py-2.5;
        &.focus {
            @apply border-gray-400 ring ring-gray-200;

        }
    }

	.error {
		@apply text-base text-red-500;
	}
}

.checkbox {
    label {
        @apply inline-flex items-center cursor-pointer;

        input {
            @apply
                h-6 w-6 mr-3 text-primary rounded border-gray-300 border-2
                focus:ring focus:ring-primary focus:ring-opacity-50;
        }
    }
}

label.toggle {
	@apply relative inline-block w-7 h-4;

	input {
		@apply opacity-0 w-0 h-0;
	}

	.slider {
		@apply 
			absolute cursor-pointer 
			top-0 left-0 right-0 bottom-0 
			bg-gray-300 rounded-full
			transition-all duration-300;
	}
  
	.slider:before {
		content: "";
		@apply
			absolute 
			h-3 w-3 left-0.5 bottom-0.5
			bg-white rounded-full
			transition-all duration-300;
	}
  
	input:checked + .slider {
		@apply bg-primary;
	}
  
	input:checked + .slider:before {
		@apply transform translate-x-full;
	}
}

.radio-toggle {
    @apply flex flex-grow;

    input[type="radio"] {
        @apply 
            absolute w-4 h-4 opacity-0;

        &:checked + label {
            @apply border-primary;
        }
    }

    label {
        @apply 
            w-full
            p-4
            border-2 border-gray-300 rounded-lg
            cursor-pointer;
    }
}