.pagination {
    @apply flex flex-wrap justify-center;

    li {
        @apply 
            mb-2
            text-sm font-medium tracking-wider text-gray-900
            border-r border-t border-b border-gray-200
            cursor-pointer;

        &:not(.active) {
            @apply hover:bg-gray-100;
        }

        &:not(.next):not(.previous):not(.active):not(.break-me) {
            @apply hidden md:flex;
        }

        a {
            @apply 
                inline-block
                w-10
                p-2
                text-center;
        }

        &.previous, &.next {
            a {
                @apply w-auto px-4;
            }
        }

        &.previous {
            @apply border-l rounded-l-md;
        }

        &.next {
            @apply border-r rounded-r-md ;
        }

        &.active {
            @apply bg-gray-200;
        }
    }
}