@import "react-datepicker/dist/react-datepicker.css";

.react-datepicker-wrapper {
    @apply w-full;

    .react-datepicker__input-container input {
        @apply 
			block h-11 text-base tracking-wide w-full
			border-gray-300 rounded-md
			hover:border-gray-400 transition
			focus:border-gray-400 focus:ring focus:ring-gray-200;
    }
}

.react-datepicker {
    @apply font-sans text-gray-900 border-gray-300;
    
    .react-datepicker__header  {
        @apply bg-gray-100 border-gray-300;
    }

    .react-datepicker__day--selected {
        @apply bg-primary;
    }

    // override keyboard select styling
    .react-datepicker__day--keyboard-selected {
        @apply bg-white text-gray-900;
    }

    button {
        @apply min-w-0 shadow-none rounded-none;
    }
}