button {
	min-width: 6rem;

	@apply 
		flex flex-shrink-0 items-center justify-center
		h-10 px-5
		rounded-full shadow
		text-sm font-medium tracking-wider
		text-gray-1000 bg-gray-200
		ring-gray-300
		transition;

		&.large {
			@apply 
				h-14 text-lg tracking-normal;
		}

		&.circle {
			@apply h-10 w-10 p-0 min-w-0;

			&.large {
				@apply h-14 w-14 text-lg;
			}
		}

		&.primary {
			@apply 
				text-white bg-primary
				hover:bg-primary-dark
				focus:ring-4 focus:outline-none;
		}
		&.secondary {
			@apply 
				text-gray-1000 bg-gray-200
				hover:bg-gray-300
				focus:ring-4 focus:outline-none;
		}
		&.tertiary {
			@apply 
				text-primary bg-transparent border border-gray-300 shadow-none
				hover:bg-black hover:bg-opacity-5
				focus:ring-4 focus:outline-none;
		}
		&.outline {
			@apply 
				text-gray-500 bg-transparent border border-gray-300 shadow-none
				hover:text-primary hover:bg-black hover:bg-opacity-5
				focus:ring-4 focus:outline-none;
		}
		&.text {
			@apply 
				text-gray-900 bg-transparent border-none shadow-none
				hover:bg-gray-200
				focus:ring-4 focus:outline-none;
		}
		&.link {
			@apply 
				px-0 h-auto min-w-0
				text-gray-1000 underline bg-transparent border-none shadow-none
				hover:bg-transparent hover:text-gray-800
				focus:text-gray-800;
		}
		&.toggle {
			@apply 
				bg-transparent border border-gray-200 shadow-none
				hover:bg-gray-100
				focus:outline-none;

			&.active {
				@apply
					bg-gray-100 border-gray-700
					hover:bg-gray-200;
			}
		}
}